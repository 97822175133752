<template>
  <section class="dashboard-ctn">
    <iframe v-if="url"
      :src="url"
      style="display: flex;"
      frameborder="0"
      width="100%"
      height="100%"
      allowtransparency
      @load="hideLoading"
    >
    </iframe>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'
import md5 from 'blueimp-md5'

export default {
  components: {},
  data() {
    return {
      baseService: new BaseServices(this),
      userData: null,
      roleKey: {
        seller: 'shipper',
        ecommerce: 'organization',
        marketplace: 'organization'
      },
      roleCode: Object.freeze({
        admin: '16113811-706d-460e-8056-0fc6eea71702',
        superadmin: '16113811-706d-460e-8056-0fc6eea71702',
        seller: 'a97d1553-d882-47b7-8f14-b03dac40097d',
        ecommerce: 'a97d1553-d882-47b7-8f14-b03dac40097d',
        marketplace: 'c8a75ad9-05b5-41d4-a8d0-aa75dd319add'
      }),
      url: ''
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    mySession () {
      if (!this.userData) this.userData = this.$session.get('cas_user')
    },
    userData () {
      if (!this.userData) return
      this.setUrlConfig()
    }
  },
  mounted () {
    this.$loadingAlert({text: 'Obteniendo datos, por favor espere...', color: 'primary'})
    this.userData = this.$session.get('cas_user')
  },
  methods: {
    setUrlConfig() {
      const role = this.userData.role
      if (['admin'].includes(role)) {
        this.setDashboardStudioUrl(this.roleCode[role], null)
      } else if (['ecommerce'].includes(role) && this.userData?.user_access?.company_category_id === 2) {
        const params = { shipper_id: this.$session.get('cas_user')?.shipper?.id }
        this.baseService.callService('getShipper', null, params)
          .then(resp => {
            const company_md5 = md5(`${this.userData[this.roleKey[role]].id}-${resp.data.legacy_data?.code}`)
            const urlParams = { cp: company_md5 }
            this.setDasboardFreemium(JSON.stringify(urlParams))
          })
          .catch(() => this.hideLoading())
      }
      else {
        const company_id = this.userData[this.roleKey[role]].id
        const isSeller = role === 'seller'
        const apiName = isSeller ? 'getShipper' : 'getOrganization'
        const params = isSeller ? { shipper_id: company_id } : { id: company_id }
        this.baseService.callService(apiName, null, params)
          .then(resp => {
            // Seller -> shipper.id || mkp y eccomerse -> organization.id
            const company_md5 = md5(`${this.userData[this.roleKey[role]].id}-${resp.data.legacy_data?.code}`)
            const report_code = resp.data.legacy_data?.customization?.dashboard_code ?? this.roleCode[role]
            const params = role === 'marketplace' ? `{"mp0":"${company_md5}","mp1":"${company_md5}"}` : `{"cp":"${company_md5}","cp0":"${company_md5}","cp1":"${company_md5}"}`
            this.setDashboardStudioUrl(report_code, params)
          })
          .catch(() => this.hideLoading())
      }
    },
    setDashboardStudioUrl(report_code, params) {
      this.url = `https://lookerstudio.google.com/embed/reporting/${report_code}${params ? `?params=${params}` : ''}`
    },
    setDasboardFreemium(params) {
      this.url = `https://lookerstudio.google.com/embed/reporting/09174ea9-598c-4e21-b3ff-6e82ea3e0092/page/jOk5D?params=${params}`
    },
    hideLoading() {
      this.m_closeAlertLoading()
    }
  }
}
</script>

<style lang="scss">
.dashboard-ctn {
  height: 80vh;
}
</style>